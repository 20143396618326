import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueFilter from 'vue-filter';
import Nl2br from 'vue-nl2br'
import { BootstrapVue } from 'bootstrap-vue'
import { func } from './scripts/scripts'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueScreen from 'vue-screen'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './app.scss'

import VueSimpleAlert from 'vue-simple-alert'

Vue.use(VueSimpleAlert)
Vue.use(VueFilter); // Vue V2
Vue.component('nl2br', Nl2br)

NProgress.configure({ showSpinner: true })

export const globalStore = new Vue({
  data: {}
})

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

Vue.use(VueScreen, 'bootstrap')

Vue.prototype.$func = func
Vue.prototype.$globals = globalStore

Vue.filter('truncate', function (text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

var permission = {
  RECHT_Read: 0,
  RECHT_Create: 1,
  RECHT_Update: 2,
  RECHT_Delete: 3,
  RECHT_Access_Responsible: 98,
  RECHT_Access_Own: 99,
  RECHT_Access: 100
}
Vue.prototype.$permission = permission

var module = {
  IDENTIFIER_Ou: 'OU',
  IDENTIFIER_User: 'USER',
  IDENTIFIER_Info: 'INFO',
  IDENTIFIER_SettingsOu: 'SETTINGSOU',
  IDENTIFIER_Userdates: 'USERDATES',
  IDENTIFIER_Stammdaten: 'STAMMDATEN',
  IDENTIFIER_MYPROFILE: 'MYPROFILE',
  IDENTIFIER_MYFILE: 'MYFILE',
  IDENTIFIER_MYPASSWORD: 'MYPASSWORD',
  IDENTIFIER_Lion: 'LION',
  IDENTIFIER_Firmung: 'FIRMUNG',
  IDENTIFIER_Event: 'EVENT'
}
Vue.prototype.$module = module

Vue.config.productionTip = false

window.App = new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
