import axios from 'axios'
import store from '../store'
import NProgress from 'nprogress'
import { func } from '@/scripts/scripts'
import router from '@/router';

export const apiClient = axios.create({
  baseURL: '/moduls_new/'
})

apiClient.interceptors.request.use(
  config => {
    const token = store.getters.getToken
    NProgress.start()
    config.headers['X-Application'] = 'api'

    if (token) {
      config.headers['X-Token'] = 'Bearer ' + token
    } else if (func.isLocalStorage()) {
      var tmpToken = localStorage.getItem('token')
      if (tmpToken) {
        console.log('use token from local storage')
        config.headers['X-Token'] = 'Bearer ' + tmpToken
      }
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    console.log(error)

    Promise.reject(error)
  })

// axios response interceptor
apiClient.interceptors.response.use(
  function (response) {
    NProgress.done() // Set the loading progress bar (end...)
    return response
  },
  function (error) {
    NProgress.done()

    if (router.currentRoute !== null && router.currentRoute !== undefined && router.currentRoute.name !== null && router.currentRoute.name.endsWith('login')) {
      return Promise.reject(error)
    } else {
      if (error.response.status === 401) {
        router.push('/login')
      }
    }

    return Promise.reject(error)
  }
)

export default {}
