import { apiClient } from './AbstractHTTPService'

const PATH = '/myou/'

export default {
  getAllLocations () {
    return apiClient.get(PATH + 'all/')
  },
  getRegisterLocations () {
    return apiClient.get(PATH + 'register/')
  },
  getLocations () {
    return apiClient.get(PATH)
  },
  setLocations (location) {
    return apiClient.put(PATH + location.id + '/', location)
  },
  getLocationSettings () {
    return apiClient.get('/firmis/settingsou/')
  },
  updateLocationSettings (settings, location) {
    return apiClient.put('/firmis/settingsou/' + location.id + '/', settings)
  }
}
