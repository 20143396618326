import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import NProgress from 'nprogress'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
  })
}

const routes = [
  {
    path: '/',
    component: () => import('../template/Start'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/Dashboard')
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('../views/User'),
        meta: { loading: true },
        children: [
          {
            path: 'files/:id',
            name: 'userfiles',
            component: () => import('../components/FileCollectionDialog'),
            props: true
          },
          {
            path: 'userpermissions/:id',
            name: 'userpermissions',
            component: () => import('../components/UserPermissionDialog'),
            props: true
          },
          {
            path: 'userevents/:id',
            name: 'userevents',
            component: () => import('../components/UserEventsDialog'),
            props: true
          },
          {
            path: 'userpoints/:id',
            name: 'userpoints',
            component: () => import('../components/UserEventsDialog'),
            props: true
          }]

      },
      {
        path: 'pointoverview',
        name: 'pointoverview',
        component: () => import('../views/PointOverview')
      },
      {
        path: 'userregisterrequests',
        name: 'userregisterrequests',
        component: () => import('../views/UserRegisterRequests')
      },
      {
        path: 'userfilerequests',
        name: 'userfilerequests',
        component: () => import('../views/UserFileRequests')
      },
      {
        path: 'settingsou',
        name: 'settingsou',
        component: () => import('../views/SettingsOu'),
        meta: { loading: true }
      },
      {
        path: 'firmung',
        name: 'firmung',
        component: () => import('../views/Firmung'),
        children: [
          {
            path: 'files/:id',
            name: 'firmfiles',
            component: () => import('../components/FileCollectionDialog'),
            props: true
          },
          {
            path: 'firmparticipant/:id',
            name: 'firmparticipant',
            component: () => import('../components/FirmParticipantDialog'),
            props: true
          }
        ]
      },
      {
        path: 'event/:mode?/:modeId?',
        props: true,
        name: 'event',
        component: () => import('../views/Event'),
        children: [
          {
            path: 'files/:id',
            name: 'eventfiles',
            component: () => import('../components/FileCollectionDialog'),
            props: true
          },
          {
            path: 'eventparticipant/:id',
            name: 'eventparticipant',
            component: () => import('../components/EventParticipantDialog'),
            props: true
          }
        ]
      },
      {
        path: 'mandatoryparticipation',
        name: 'mandatoryparticipation',
        component: () => import('../views/MandatoryParticipation')
      },
      {
        path: 'eventoverview',
        name: 'eventoverview',
        component: () => import('../views/EventOverview'),
        meta: { loading: true }
      },
      {
        path: 'myfirmung',
        name: 'myfirmung',
        component: () => import('../views/MyFirmung'),
        meta: { loading: true }
      },
      {
        path: 'myfirmwunsch',
        name: 'myfirmwunsch',
        component: () => import('../views/MyFirmWunsch'),
        meta: { loading: true }
      },
      {
        path: 'firmwunsch',
        name: 'firmwunsch',
        component: () => import('../views/FirmWunsch')
      },
      {
        path: 'firmwunsch-dragdrop',
        name: 'firmwunsch-dragdrop',
        component: () => import('../views/FirmWunschVerteilung'),
        meta: { loading: true }
      },
      {
        path: 'myevents',
        name: 'myevents',
        component: () => import('../views/EventOverview'),
        meta: { loading: true }
      },
      {
        path: 'eventtype',
        name: 'eventtype',
        component: () => import('../views/EventType')
      },
      {
        path: 'rechte',
        name: 'rechte',
        component: () => import('../views/Rechte')
      },
      {
        path: 'rechtegruppen',
        name: 'rechtegruppen',
        component: () => import('../views/RechteGruppe')
      },
      {
        path: 'skill',
        name: 'skill',
        component: () => import('../views/Skill')
      },
      {
        path: 'ouskill',
        name: 'ouskill',
        component: () => import('../views/OuSkill')
      },
      {
        path: 'filecategory',
        name: 'filecategory',
        component: () => import('../views/FileCategory')
      },
      {
        path: 'impressum',
        name: 'impressum',
        component: () => import('../views/Impressum')
      },
      {
        path: 'datenschutz',
        name: 'datenschutz',
        component: () => import('../views/Datenschutz')
      }, {
        path: 'profile',
        name: 'profile',
        component: () => import('../views/UserProfile'),
        meta: { loading: true }
      }, {
        path: 'myfile',
        name: 'myfile',
        component: () => import('../views/UserMyFile')
      }, {
        path: 'deleteprofile',
        name: 'deleteprofile',
        component: () => import('../views/UserDelete')
      }, {
        path: 'password',
        name: 'password',
        component: () => import('../views/UserPassword')
      }, {
        path: 'qr',
        name: 'qr',
        component: () => import('../views/QrCodes')
      }, {
        path: 'info',
        name: 'info',
        component: () => import('../views/Task'),
        children: [
          {
            path: 'files/:id',
            name: 'infofiles',
            component: () => import('../components/FileCollectionDialog'),
            props: true
          }
        ]
      }, {
        path: 'myinfo',
        name: 'myinfo',
        component: () => import('../views/MyTask'),
        meta: { loading: true }
      }
    ]
  },
  {
    path: '/register/:location?',
    name: 'register',
    component: () => import('../views/Register'),
    props: true
  },
  {
    path: '/publicevents/:hash?',
    name: 'publicevents',
    component: () => import('../views/PublicEventOverview'),
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login')
  },
  {
    path: '/pwvergessen',
    name: 'pwvergessen',
    component: () => import('../views/PasswordForgotten')
  },
  {
    path: '/pwreset',
    name: 'resetpassword',
    component: () => import('../views/ResetPassword')
  }, {
    path: '/seeyou',
    name: 'seeyou',
    component: () => import('../views/SeeYou')
  }, {
    path: '/datenschutzPublic',
    name: 'datenschutzPublic',
    component: () => import('../views/DatenschutzPublic')
  }, {
    path: '*',
    redirect: '/'
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function checkPermission(to) {
  var MODULE = Vue.prototype.$module
  var PERMISSION = Vue.prototype.$permission

  to = to.toLowerCase()
  if (to.startsWith('/user')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_User, PERMISSION.RECHT_Create) &&
      !store.getters.hasPermission(MODULE.IDENTIFIER_User, PERMISSION.RECHT_Update) &&
      !store.getters.hasPermission(MODULE.IDENTIFIER_User, PERMISSION.RECHT_Delete)) {
      return false
    }
  } else if (to.startsWith('/rechte') || to.startsWith('/skill') || to.startsWith('/eventtype') || to.startsWith('/filecategory')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_Stammdaten, PERMISSION.RECHT_Access)) {
      return false
    }
  } else if (to.startsWith('/settingsou') || to.startsWith('/mandatoryparticipation') || to.startsWith('/ouskill')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_SettingsOu, PERMISSION.RECHT_Access)) {
      return false
    }
  } else if (to.startsWith('/datetype')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_Userdates, PERMISSION.RECHT_Create)) {
      return false
    }
  } else if (to.startsWith('/userdates')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_Userdates, PERMISSION.RECHT_Read)) {
      return false
    }
  } else if (to.startsWith('/info')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_Info, PERMISSION.RECHT_Access) && !store.getters.hasPermission(MODULE.IDENTIFIER_Info, PERMISSION.RECHT_Access_Responsible)) {
      return false
    }
  } else if (to.startsWith('/myinfo')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_Info, PERMISSION.RECHT_Access_Own)) {
      return false
    }
  } else if (to.startsWith('/profile') || to.startsWith('/deleteprofile')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_MYPROFILE, PERMISSION.RECHT_Access_Own)) {
      return false
    }
  } else if (to.startsWith('/myfile')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_MYFILE, PERMISSION.RECHT_Access_Own)) {
      return false
    }
  } else if (to.startsWith('/password')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_MYPASSWORD, PERMISSION.RECHT_Access_Own)) {
      return false
    }
  } else if (to.startsWith('/lionreads')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_Lion, PERMISSION.RECHT_Read)) {
      return false
    }
  } else if (to.startsWith('/myfirmung')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_Firmung, PERMISSION.RECHT_Access_Own)) {
      return false
    }
  } else if (to.startsWith('/firmung') || to.startsWith('/firmwunsch')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_Firmung, PERMISSION.RECHT_Access)) {
      return false
    }
  } else if (to.startsWith('/eventoverview') || to.startsWith('/myevents')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_Event, PERMISSION.RECHT_Access_Own)) {
      return false
    }
  } else if (to.startsWith('/event')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_Event, PERMISSION.RECHT_Access) && !store.getters.hasPermission(MODULE.IDENTIFIER_Event, PERMISSION.RECHT_Access_Responsible)) {
      return false
    }
  } else if (to.startsWith('/pointoverview')) {
    if (!store.getters.hasPermission(MODULE.IDENTIFIER_Event, PERMISSION.RECHT_Access)) {
      return false
    }
  }

  return true
}

router
  .beforeEach((to, from, next) => {
    store.dispatch('menu/setLoading', false)

    NProgress.start()

    // console.log('...' + to.path)
    if (to.path.includes('/register')) {
      store.dispatch('logout')
      next()
      return true
    }

    if (to.path.includes('/publicevents')) {
      next()
      return true
    }

    if (to.path.includes('/datenschutzPublic')) {
      store.dispatch('datenschutzPublic')
      next()
      return true
    }

    if (to.path.includes('/seeyou')) {
      store.dispatch('logout')
      next()
      return true
    }

    if (to.path === '/login') {
      store.dispatch('logout')
      next()
      return true
    }

    if (to.path.includes('/pwvergessen')) {
      store.dispatch('logout')
      next()
      return true
    }

    if (to.path.includes('/pwreset')) {
      store.dispatch('logout')
      next()
      return true
    }

    if (to.path) {
      console.log('ROUTER ' + to.path)

      if (to.path !== '/login' && (!store.getters.getToken || !store.getters.getUser || !store.getters.getLocation)) {
        store.dispatch('fetchToken').then(function (data) {
          if (!checkPermission(to.path)) {
            NProgress.done()
            return true
          }
          next()
        }).catch((e) => {
          next('/login')
        })
      } else {
        if (!checkPermission(to.path)) {
          NProgress.done()
          return true
        }
        next()
      }

      store.dispatch('menu/setActive', { path: to.path })

      if (to.meta.loading) {
        store.dispatch('menu/setLoading', true)
      }
    }
  })

router.afterEach(() => {
  NProgress.done()
})
export default router
