import store from '@/store'

export const func = {
  loadScript: (script, callback) => {
    const tag = document.createElement('script');
    tag.setAttribute('src', script);
    tag.onload = callback
    document.head.appendChild(tag);
  },
  loadDatatable: (callback) => {
    func.loadScript('/static/datatables.min.js', callback)
  },

  replaceURL: (string) => {
    var res = string.match(/((((http|https|ftp|ftps):\/\/)|(www\.))[\w?=&./-;#~%-]+)/g);
    if (Array.isArray(res)) {
      res = res.filter(function (x, i, a) {
        return a.indexOf(x) === i;
      });

      res.forEach(function (url, index) {
        string = string.replace(url, '<a href="' + url + '" target="_blank">' + url + '</a>');
      });
    }
    return string;
  },

  isset: (data) => {
    if (data !== null && data !== undefined && data !== false) {
      return true
    }
    return false
  },

  uuid: () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2)
  },

  addAuth: (obj) => {
    obj.url = '/moduls_new' + obj.url
    obj.headers = {
      'X-Token': store.getters.getToken,
      'X-Application': 'JWT'
    }

    return obj
  },

  addDefaultButtons: (objArr) => {
    objArr.push({
      extend: 'colvis',
      columns: ':not(.not-export-col)',
      postfixButtons: ['colvisRestore']
    })

    objArr.push({
      extend: 'collection',
      text: 'Export',
      buttons: [
        {
          extend: 'excel',
          exportOptions: {
            columns: ':not(.not-export-col)'
          }
        },
        {
          extend: 'csv',
          exportOptions: {
            columns: ':not(.not-export-col)'
          }
        }, {
          extend: 'pdf',
          orientation: 'landscape',
          exportOptions: {
            columns: ':visible :not(.not-export-col)'
          },
          customize: function (doc) {
            doc.content.splice(1, 0, {
              margin: [0, 0, 0, 12],
              alignment: 'center',
              text: ''
            })
          },
          messageBottom: ''
        }
      ]
    })

    return objArr
  },

  redirect: function (link, target = '_blank') {
    window.open(link, target)
  },

  isLocalStorage: function () {
    if (typeof localStorage !== 'undefined') {
      try {
        localStorage.setItem('feature_test', 'yes')
        if (localStorage.getItem('feature_test') === 'yes') {
          localStorage.removeItem('feature_test')
          return true
        }
      } catch (e) {
        // localStorage is disabled
      }
    }
    return false
  }
}
