import Vue from 'vue'
import Vuex from 'vuex'
import menu from './modules/menu'
import selectize from './modules/selectize'
import JwtService from '../services/JwtService'
import LocationService from '../services/LocationService'
import { func } from '@/scripts/scripts'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    location: { sName: '' },
    locations: [],
    user: null,
    permission: []
  },
  mutations: {
    SET_TOKEN (state, data) {
      state.token = data
      if (data && func.isLocalStorage()) {
        console.log('SET LOCAL STORAGE TOKEN')
        localStorage.setItem('token', data)
      }
    },
    async SET_LOCATION (state, data) {
      if (data && func.isLocalStorage()) {
        console.log('SET LOCAL STORAGE LOCATION')
        localStorage.setItem('location', data.id)
      }

      state.location = data
    },
    SET_USER (state, data) {
      state.user = data
    },
    SET_LOCATIONS (state, data) {
      state.locations = data
    },
    SET_PERMISSION (state, data) {
      state.permission = data
    }
  },
  actions: {
    logout ({ commit, state }) {
      commit('SET_TOKEN', '')
      commit('SET_LOCATION', null)
      commit('SET_USER', null)
      commit('SET_LOCATIONS', '')
      commit('SET_PERMISSION', [])

      if (func.isLocalStorage()) {
        console.log('REMOVE LOCAL STORAGE TOKEN')
        localStorage.removeItem('token')
      }
    },
    changeLocation ({ commit }, data) {
      return new Promise(function (resolve, reject) {
        LocationService.setLocations(data.location)
          .then(response => {
            commit('SET_TOKEN', response.data.token)
            commit('SET_LOCATION', response.data.ou)
            commit('SET_USER', response.data.user)
            commit('SET_PERMISSION', response.data.permission_arr)

            resolve()
          })
          .catch(e => {
            reject(e)
            console.log(e)
          })
      })
    },
    login ({ commit, state }, response) {
      commit('SET_TOKEN', response.data.token)
      commit('SET_LOCATION', response.data.ou)
      commit('SET_USER', response.data.user)
      commit('SET_PERMISSION', response.data.permission_arr)
    },
    fetchToken ({ commit, state }, bForce = false) {
      return new Promise(function (resolve, reject) {
        if (!bForce && state.token) {
          resolve()
        } else {
          console.log('fetch Token')

          JwtService.getToken()
            .then(response => {
              commit('SET_TOKEN', response.data.token)
              commit('SET_LOCATION', response.data.ou)
              commit('SET_USER', response.data.user)
              commit('SET_PERMISSION', response.data.permission_arr)

              resolve()
            })
            .catch(e => {
              reject(e)
              console.log(e)
            })
        }
      })
    },
    fetchLocations ({ commit, rootState }) {
      // console.log(rootState.token);
      LocationService.getLocations()
        .then(response => {
          commit('SET_LOCATIONS', response.data)
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  getters: {
    getToken: (state) => {
      return state.token
    },
    getLocation: (state) => {
      return state.location
    },
    getLocations: (state) => {
      return state.locations
    },
    getUser: (state) => {
      return state.user
    },
    hasPermission: (state) => (module, permission) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!state.permission.hasOwnProperty(module)) {
        return false
      }
      // eslint-disable-next-line no-prototype-builtins
      if (!state.permission[module].hasOwnProperty(permission)) {
        return false
      }

      return true
    }
  },
  modules: {
    menu,
    selectize
  }
})
