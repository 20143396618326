<template>
  <div id="main">
    <router-view></router-view>
    <cookie-law v-if="showLaw" transitionName="none" style="font-size: 0.9rem;">
      <div slot-scope="props">
        <div class="Cookie Cookie--bottom Cookie--base">
          <div class="Cookie__content">
            Wir verwenden die Web Storage Technologie, um Daten Ihres letzten Besuchs zu speichern. Diese Daten werden
            nicht zu Werbezwecken verwendet und auch nicht an Dritte weitergegeben.
          </div>
          <div class="Cookie__buttons">
            <button @click="props.accept" class="Cookie__button"><span>Verstanden</span></button>
          </div>
        </div>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  components: {
    CookieLaw
  },
  mounted() {
  },
  data() {
    return {
      showLaw: false
    }
  },
  watch: {
    '$route'() {
      if (this.$route && this.$route.fullPath.includes('publicevents')) {
        this.showLaw = false;
      } else {
        this.showLaw = true;
      }
    }
  }
}
</script>

<style lang="scss">

</style>
