import { apiClient } from './AbstractHTTPService'

const PATH = '/task/'

export default {
  getPersonenkreisUser () {
    return apiClient.get(PATH + 'personenkreis/user/')
  },

  getUserOptions () {
    return apiClient.get(PATH + 'personenkreis/')
  },

  getMyInfos () {
    return apiClient.get(PATH + 'mytask/type/99/')
  },

  getMyDahsbordInfos () {
    return apiClient.get(PATH + 'mytask/type/99/?limit=3')
  }
}
