import Vue from 'vue'
import Vuex from 'vuex'
import FileService from '../../services/FileService'
import SkillService from '../../services/SkillService'
import TaskService from '@/services/TaskService';

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    fileCategories: [],
    skills: [],
    personenkreise: []
  },
  mutations: {
    SET_FILECATEGORIES (state, data) {
      state.fileCategories = data
    },
    SET_SKILLS (state, data) {
      state.skills = data
    },
    SET_PERSONENKREISE (state, data) {
      state.personenkreise = data
    }
  },
  actions: {
    async fetchFileCategories ({ commit, state }) {
      return new Promise(function (resolve, reject) {
        if (state.fileCategories && state.fileCategories.length > 0) {
          resolve()
        } else {
          FileService.getCategories()
            .then(response => {
              commit('SET_FILECATEGORIES', response.data)
              resolve()
            })
            .catch(e => {
              reject(e)
              console.log(e)
            })
        }
      })
    },
    async fetchSkills ({ commit, state }) {
      return new Promise(function (resolve, reject) {
        if (state.skills && state.skills.length > 0) {
          resolve()
        } else {
          SkillService.getSkills()
            .then(response => {
              commit('SET_SKILLS', response.data)
              resolve()
            })
            .catch(e => {
              reject(e)
              console.log(e)
            })
        }
      })
    },
    async fetchPersonenkreise ({ commit, state }) {
      return new Promise(function (resolve, reject) {
        TaskService.getUserOptions()
          .then(response => {
            commit('SET_PERSONENKREISE', response.data)
            resolve()
          })
          .catch(e => {
            reject(e)
            console.log(e)
          })
      })
    }
  },
  getters: {
    getFileCategories: (state) => {
      return state.fileCategories
    },
    getSkills: (state) => {
      return state.skills
    },
    getPersonenkreise: (state) => {
      return state.personenkreise
    }
  },
  modules: {}
}
