import Vue from 'vue'
import Vuex from 'vuex'
import MenuService from '../../services/MenuService'

Vue.use(Vuex)

function handleActiveState (data, path = null) {
  return data.map(x => {
    x.active = false

    if (path && x.sPath === path) {
      x.active = true
    }

    if (x.oSubmenu_arr !== null && Array.isArray(x.oSubmenu_arr) && x.oSubmenu_arr.length > 0) {
      x.oSubmenu_arr = handleActiveState(x.oSubmenu_arr, path)
    }

    return x
  })
}

export default {
  namespaced: true,
  state: {
    menu: [],
    active: '',
    loading: false
  },
  mutations: {
    SET_MENU (state, menu) {
      state.menu = handleActiveState(menu, state.active)
    },
    ADD_MENU (state) {
      state.menu.push({
        isHeading: true,
        name: 'test'
      })
    },
    SET_ACTIVE (state, path) {
      state.active = path
      handleActiveState(state.menu, path)
    },
    SET_LOADING (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    fetchMenu ({ commit }) {
      MenuService.getMenu()
        .then(response => {
          commit('SET_MENU', response.data)
        })
        .catch(e => {
          console.log(e)
        })
    },
    addMenu ({ commit }) {
      commit('ADD_MENU')
    },
    setActive ({ commit }, data) {
      commit('SET_ACTIVE', data.path)
    },
    setLoading ({ commit }, data) {
      commit('SET_LOADING', data)
    }
  },
  getters: {
    getMenu: (state) => {
      return state.menu
    },
    getLoading: (state) => {
      return state.loading
    }
  },
  modules: {}
}
